<template>
  <a-form
    :label-col="formLayout.labelCol"
    :wrapper-col="formLayout.wrapperCol"
    v-bind="rformProps.modelRef"
    @submit="onSubmit"
  >
    <a-form-item label="上级：" name="parent_id">
      <my-department-select
        v-model:value="model.parent_id"
      ></my-department-select>
    </a-form-item>
    <a-form-item
      label="团队名称："
      name="project_no"
      v-bind="validateInfos['name']"
    >
      <a-input type="text" placeholder="名称" v-model:value="model.name" />
    </a-form-item>
    <a-form-item :wrapper-col="formLayout.submitWrapperCol">
      <a-button type="primary" html-type="submit" @click="onSubmit">
        保存
      </a-button>
      <a-button style="margin-left: 10px" @click="$emit('closeModel')">
        关闭
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
  import { Form } from 'ant-design-vue'
  const useForm = Form.useForm
  import { reactive, onMounted } from 'vue'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  import MyDepartmentSelect from '@/common/components/Select/MyDepartmentSelect.vue'
  export default {
    inject: ['formLayout'],
    emits: ['finish'],
    components: { MyDepartmentSelect },
    props: {
      data: {
        type: Object,
      },
    },
    setup(props, context) {
      const modelRef = reactive({
        //表单
        id: undefined,
        name: undefined,
        parent_id: undefined,
      })
      const rulesRef = reactive({
        //校验
        name: [
          {
            required: true,
            type: 'string',
            message: '请输入部门名称！',
            trigger: 'change',
          },
        ],
      })

      const { validate, validateInfos } = useForm(modelRef, rulesRef)

      const onSubmit = (e) => {
        //提交
        e.preventDefault()
        validate()
          .then(() => {
            onSubmitForm()
          })
          .catch((err) => {
            console.log('error', err)
          })
      }

      const onSubmitForm = () => {
        requests({
          url: API.departments.Update,
          data: modelRef,
        })
          .then((res) => {
            notification['success']({
              message: '提示',
              description: '编辑成功！',
            })
            context.emit('finish')
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const rformProps = {
        modelRef,
        rulesRef,
      }
      onMounted(() => {
        let data = props.data.value
        modelRef.id = data.id
        modelRef.name = data.name
        modelRef.parent_id = data.parent_id ? data.parent_id : undefined
      })
      return {
        rformProps,
        model: modelRef,
        rules: rulesRef,
        validateInfos,
        onSubmit,
      }
    },
  }
</script>
